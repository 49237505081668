<template>
  <div id="generate" class="page-content">
    <div class="row">
      <div class="col-lg-12 mb-4">
        <div class="main-profile p-1 p-md-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="heading-section text-center text-md-start">
                <!--                <b-badge class="bg-success">v2</b-badge>-->
                <h4><em>Generate</em> QR Code</h4>
              </div>
              <!-- Alert messages for desktop -->
              <div class="d-none d-md-block mb-4" id="alert-message-desktop" v-if="allowGeneration || showLoginAlert">
                <b-alert variant="warning" show v-if="allowGeneration">
                  Uh-oh! It seems like your token supply for generating new images has run out. 🚫🎨 <u><b>
                  <router-link class="text-dark" to="/profile">Get more tokens!!</router-link>
                </b></u>
                </b-alert>
                <b-alert variant="warning" show v-else-if="showLoginAlert">
                  🚀 Hold on tight! <u><b style="cursor: pointer" @click="loginWithGoogle">Sign in</b></u> to unleash
                  the power of AI image generation! 🌟
                </b-alert>
              </div>
              <div class="generate-form">
                <b-form @submit="onSubmit" @reset="onReset">
                  <div class="row">
                    <div class="col-md-7 pe-md-4">

                      <pre v-if="false" class="bg-dark text-white p-3">{{ selectedIntegration }}</pre>

                      <div class="form-block mb-4">
                        <div class="block">
                          <h4 class="mb-2"><span>#</span>1</h4>
                          <!--                            <div class="selected-rev mb-2" v-if="selectedIntegration.id">-->
                          <!--                              <img v-if="selectedIntegration.logo" :src="selectedIntegration.logo" :alt="selectedIntegration.title">-->
                          <!--                              <span v-else class="icon">-->
                          <!--                                <b-icon :icon="selectedIntegration.icon"></b-icon>-->
                          <!--                              </span>-->
                          <!--                            </div>-->

                          <div v-if="selectedIntegration.formType === 'wifi'">
                            <b-row>
                              <b-col md="6" class="mb-2">
                                <b-form-group id="networkName" label="Name (SSID):" label-for="inputNetworkName">
                                  <b-form-input id="inputNetworkName" v-model="form.text.networkName"
                                                required></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group id="networkPassword" label="Password:" label-for="inputNetworkPassword">
                                  <b-form-input id="inputNetworkPassword" v-model="form.text.networkPassword"
                                                type="password" required></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group id="networkType" label="Type:" label-for="inputNetworkType">
                                  <b-form-select class="form-control" id="inputNetworkType"
                                                 v-model="form.text.networkType" :options="networkTypeOptions"
                                                 required></b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="6" class="mb-2">
                                <b-form-group id="hiddenNetwork" label="Hidden Network:" label-for="inputHiddenNetwork">
                                  <b-form-select class="form-control" id="inputHiddenNetwork"
                                                 v-model="form.text.hiddenNetwork"
                                                 :options="hiddenNetworkOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <span class="mt-3 preview-link" v-if="false">
                                {{ formattedText }}
                              </span>
                          </div>

                          <b-form-group
                              v-else
                              id="input-group-1"
                              :label="selectedIntegration.label ?? 'Enter URL:'"
                              label-for="input-1"
                              :validated="isTextValid">
                            <b-form-input
                                id="input-1"
                                v-model="form.text"
                                type="text"
                                ref="textInput"
                                :placeholder="selectedIntegration.placeholder ?? 'e.g. https://example.com'"
                                required>
                            </b-form-input>
                            <a class="mt-3 preview-link"
                               v-if="selectedIntegration.showPreview !== false && form.text.length"
                               :href="formattedText" target="_blank">
                              {{ formattedText }}
                              <b-icon icon="link"/>
                            </a>
                          </b-form-group>

                        </div>
                        <hr class="m-0">
                        <div class="block">
                          <div class="row mb-4">
                            <div class="col-6">
                              <div class="label">Integrations</div>
                            </div>
                            <div class="col-6 text-end">
                              <b-link @click="openModal" class="view-all">
                                View all
                                <b-icon icon="arrow-right"></b-icon>
                              </b-link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4 col-md-3 col-lg-2 px-2 mb-2 mb-lg-0"
                                 v-for="(item, index) in featuredIntegration" :key="index">
                              <div class="integration-item" @click="selectIntegration(item)">
                                <div class="logo-container" :class="{'i-selected': item.id === selectedIntegration.id}">
                                  <img v-if="item.logo" class="i-logo" :src="item.logo" :alt="item.title">
                                  <b-icon v-else class="i-logo" :icon="item.icon"/>
                                </div>
                                <span>{{ item.title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr class="m-0" v-if="useShortLink">
                        <div class="block" v-if="useShortLink">
                          <div class="row mb-2">
                            <div class="col-6">
                              <div class="label">
                                <b-icon class="text-success" icon="check-circle-fill"/>
                                Advanced linking
                              </div>
                            </div>
                            <div class="col-6 text-end" v-if="false">
                              <b-link @click="openModal" class="view-all">
                                <b-icon icon="gear-wide-connected"></b-icon>
                                Connect Pixel
                              </b-link>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="small text-secondary">
                                Monitor link engagement: Trace clicks, countries, platforms, browsers.
<!--                                <br>-->
<!--                                Easily edit later: Generate QR code now, modify link anytime.-->
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="form-block mb-4">
                        <div class="block">
                          <h4 class="mb-2"><span>#</span>2</h4>
                          <b-form-group
                              class="mb-2"
                              v-if="selectedStyle.showPrompt"
                              id="input-group-2"
                              label="Enter Prompt:"
                              label-for="input-2"
                              :validated="isPromptValid">
                            <b-textarea
                                id="input-2"
                                v-model="form.prompt"
                                placeholder="Describe the image you want, e.g. Utopian amazon rainforest"
                                rows="4"
                                required>
                            </b-textarea>
                            <div class="text-end">
                              <small class="form-text text-light">{{ form.prompt.length }} / {{
                                  promptMaxLength
                                }}</small>
                            </div>
                          </b-form-group>
                          <b-form-group
                              id="input-group-33"
                              label="Themes"
                              label-for="input-2"
                              :validated="isPromptValid">
                            <div class="row px-1 style-select">
                              <div class="col-4 col-lg-3 mb-3 px-2" v-for="(style) in styles" :key="style.id">
                                <div
                                    class="theme-item mb-2"
                                    :class="{'selected': selectedStyle.id === style.id}"
                                    @click="selectStyle(style)">
                                  <img class="img-fluid" v-if="style.imgLink" :src="style.img" :alt="style.title">
                                  <StoreImage v-else :clickable="false" :class-name="''" :src="style.img"
                                              :alt="style.title"/>
                                </div>
                                <div class="theme-name">{{ style.title }}</div>
                              </div>
                            </div>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="form-block d-none d-md-block">
                        <div class="block">
                          <h4 class="mb-4"><span>#</span>Advance Option</h4>
                          <b-form-group class="mb-2" id="percentage" label="QR code strength (Recommended: 75%):"
                                        label-for="sliderPercentage">
                            <b-form-input type="number" id="sliderPercentage" v-model="form.selectedPercentage" min="1"
                                          max="100" step="1"></b-form-input>
                          </b-form-group>
                          <p class="input-info">
                            The percentage indicates how much the theme will be implemented.
                            A higher percentage means more emphasis on the QR code,
                            while a lower percentage means more emphasis on the theme.
                          </p>
                        </div>
                      </div>

                      <pre v-if="false" class="bg-dark text-white mb-3">{{ form }}</pre>

                    </div>
                    <div class="col-md-5">
                      <!-- Alert messages for mobile -->
                      <div class="d-block d-md-none mb-4" id="alert-message-mobile"
                           v-if="allowGeneration || showLoginAlert">
                        <b-alert variant="warning" show v-if="allowGeneration">
                          Uh-oh! It seems like your token supply for generating new images has run out. 🚫🎨 <u><b>
                          <router-link class="text-dark" to="/profile">Get more tokens!!</router-link>
                        </b></u>
                        </b-alert>
                        <b-alert variant="warning" show v-else-if="showLoginAlert">
                          🚀 Hold on tight! <u><b style="cursor: pointer" @click="loginWithGoogle">Sign in</b></u> to
                          unleash the power of AI image generation! 🌟
                        </b-alert>
                      </div>
                      <div v-if="showResult">
                        <div v-if="imageData && imageData.images">
                          <div class="row">
                            <div class="col" v-for="(image, index) in imageData.images" :key="index">
                              <StoreImage
                                  :clickable="false"
                                  :allow-download="allowDownload"
                                  :show-download="true" class-name="border-24" :src="image"/>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="imageData && imageData.images === null">
                          <div class="main-border-button border-no-active">
                            <a href="#">
                              Oops! 😑 It didn't work as expected. Please give it another shot. The AI 🤖 tried its
                              best, but it seems like creating the image turned into a wild adventure 🌪️
                            </a>
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="col-12" v-if="showLoadingMessage">
                              <div class="main-border-button border-no-active mb-3">
                                <a class="loading-message" href="#">
                                  Please be patient! The estimated time for your request is between 10 sec to 2 min. ⏳✨
                                  Our AI wizards are working their magic to generate your image. It'll be ready in a
                                  moment. Take a breath, sip some coffee ☕️, and get ready for an enchanting result!
                                </a>
                              </div>
                            </div>
                            <div class="col-12">
                              <b-skeleton-img class="border-24" width="100%" style="padding-top: 100%"></b-skeleton-img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="output-placeholder"></div>
                      </div>
                      <b-button
                          class="submit-btn w-100 mt-3" type="submit" variant="primary"
                          :disabled="isLoading || allowGeneration || !isTextValid || !isPromptValid">
                        Generate
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="col-lg-12" v-if="showResult">-->
      <!--        <div class="main-profile">-->
      <!--          <div class="row">-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <b-modal
          size="lg"
          header-class="i-header"
          title="Add Integration"
          v-model="modalVisible"
          :hide-footer="true"
          @hide="handleModalHide">
        <div class="row p-4">
          <div class="col-12 mb-4" v-for="(group, index) in integrationGroups" :key="index">
            <div class="i-section">
              <div class="section-name">{{ group.title }}</div>
              <p class="mb-4">{{ group.subtitle }}</p>
              <div class="row" v-if="group.type === 'tiles'">
                <div class="col-3 col-md-2 px-2 mb-4" v-for="(item, index) in group.integrations" :key="index">
                  <div class="integration-item" @click="selectIntegration(item)">
                    <div class="logo-container" :class="{'i-selected': item.id === selectedIntegration.id}">
                      <img v-if="item.logo" class="i-logo" :src="item.logo" :alt="item.title">
                      <b-icon class="i-logo" v-else :icon="item.icon"/>
                    </div>
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-6 mb-4" v-for="(item, index) in group.integrations" :key="index">
                  <div class="integration-list-item"
                       @click="selectIntegration(item)"
                       :class="{'i-selected': item.id === selectedIntegration.id}">
                    <div class="row">
                      <div class="col-4 pe-0">
                        <div class="logo-container">
                          <img v-if="item.logo" class="i-logo" :src="item.logo" :alt="item.title">
                          <b-icon class="i-logo" v-else :icon="item.icon"/>
                        </div>
                      </div>
                      <div class="col-8 ps-0 py-3 pe-4 center-div">
                        <div>
                          <span>{{ item.title }}</span>
                          <p>{{ item.subtitle }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a target="_blank" style="font-size: 12pt" href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {doc, onSnapshot} from "firebase/firestore";
import StoreImage from "../components/StoreImage";
import integrations from "../utils/integrations";
import shortLink from "../utils/short-link";

export default {
  name: "Generate",
  components: {StoreImage},
  data() {
    return {
      form: {
        text: '',
        prompt: '',
        selectedPercentage: 75
      },
      userPrompt: "",
      showResult: false,
      loading: false,
      unsubscribe: null,
      imageData: null,
      isLoading: false,
      showLoginAlert: false,
      showLoadingMessage: false,
      promptMaxLength: 350,
      selectedStyle: {},
      styles: [],
      modalVisible: false,
      selectedIntegration: {},
      featuredIntegration: integrations.featuredIntegration,
      integrationGroups: integrations.integrationGroups,
      networkTypeOptions: [
        {value: 'WEP', text: 'WEP'},
        {value: 'WPA', text: 'WPA'},
        {value: 'nopass', text: 'No Password'},
      ],
      hiddenNetworkOptions: [
        {value: 'true', text: 'Yes'},
        {value: 'false', text: 'No'},
      ],
      useShortLink: false,
    }
  },
  computed: {
    ...mapState(['user', 'userDetails']),
    ...mapGetters(['db', 'getGenerateData']),
    allowGeneration() {
      // console.log("this.userDetails", this.userDetails)
      return this.userDetails && this.userDetails.tokens < 1;
    },
    allowDownload() {
      return this.userDetails && !!this.userDetails.isSubscribed;
    },
    isTextValid() {
      if (this.selectedIntegration.formType) {
        return true;
      }
      return this.form.text.length > 0 && this.form.text.length < 100;
    },
    isPromptValid() {
      return this.form.prompt.length > 0 && this.form.prompt.length < this.promptMaxLength;
    },
    formattedText() {
      if (this.selectedIntegration.formatValue) {
        return this.selectedIntegration.formatValue(this.form.text);
      }
      return this.form.text;
    },
  },
  methods: {
    ...mapActions(['generateImage', 'loginWithGoogle', 'trackGenerateEvent', 'getThemes']),
    ...mapMutations(['setGenerateData']),
    openModal() {
      this.modalVisible = true;
    },
    handleModalHide() {
      // Handle modal hide event if needed
    },
    selectIntegration(intObj) {
      if (this.selectedIntegration.formType || intObj.clearText) {
        this.form.text = "";
      }
      if (!this.featuredIntegration.find((item) => item.id === intObj.id)) {
        this.featuredIntegration.unshift(intObj);
      }
      if (intObj.formType) {
        this.form.text = intObj.defaultValues ?? "";
      }
      this.useShortLink = !!intObj.useShortLink;
      this.featuredIntegration = this.featuredIntegration.slice(0, 6);
      this.selectedIntegration = intObj;
      this.modalVisible = false;
    },
    selectStyle(style) {
      if (style.id === this.selectedStyle.id) {
        return;
      }
      if (this.selectedStyle.showPrompt === true && style.showPrompt === false) {
        this.userPrompt = this.form.prompt;
        this.form.prompt = style.prompt;
      } else if (this.selectedStyle.showPrompt === false && style.showPrompt === true) {
        this.form.prompt = this.userPrompt;
      } else {
        this.form.prompt = style.prompt;
      }
      this.selectedStyle = style;
    },
    redirectToAlert() {
      const alertMessageMobile = document.getElementById('alert-message-mobile');
      const alertMessageDesktop = document.getElementById('alert-message-desktop');

      // Check if the screen size is less than Bootstrap MD (medium) screen size (992 pixels)
      if (window.innerWidth < 992) {
        // Navigate to the component with id #alert-message-mobile
        if (alertMessageMobile) {
          alertMessageMobile.scrollIntoView({behavior: 'smooth'});
        }
      } else {
        // Redirect to the component with id #alert-message-desktop
        if (alertMessageDesktop) {
          alertMessageDesktop.scrollIntoView({behavior: 'smooth'});
        }
      }
    },
    async onSubmit(event) {
      event.preventDefault();
      this.trackGenerateEvent();
      if (!this.selectedIntegration.formType) {
        this.setGenerateData(this.form);
      }
      if (!this.user) {
        this.showLoginAlert = true;
        this.redirectToAlert();
        await this.loginWithGoogle();
        if (!this.user) {
          this.showLoginAlert = true;
          // console.log("User login");
          return;
        }
        this.showLoginAlert = false;
      }
      this.isLoading = true;
      try {
        this.showResult = true
        setTimeout(() => {
          // Code to be executed after 40 seconds
          this.showLoadingMessage = true;
        }, 10000); // 10 seconds in milliseconds
        const formData = {...this.form};
        formData.integration = this.selectedIntegration.title ?? null;
        formData.theme = this.selectedStyle.title ?? null;
        formData.themeId = this.selectedStyle.id ?? null;
        formData.text = this.formattedText;
        if (this.useShortLink) {
          try {
            const link = await shortLink.createShortLink(this.formattedText);
            formData.originalLink = this.formattedText;
            formData.text = link.short_url;
            formData.shortId = link.short_id;
          } catch (e) {
            console.log("Short Link error:", e);
          }
        }
        formData.options = {};
        if (this.form.selectedPercentage) {
          formData.options.guidance_end_2 = this.form.selectedPercentage / 100;
        }
        const imageDocId = await this.generateImage(formData);
        if (!imageDocId) {
          // console.log("Error generating the image")
          this.isLoading = false
          return;
        }
        const imageDocRef = doc(this.db, 'images', imageDocId)
        this.unsubscribe = onSnapshot(imageDocRef, (snapshot) => {
          const imageData = snapshot.data()
          this.imageData = imageData
          if (imageData.images && imageData.images.length) {
            this.isLoading = false
            this.showLoadingMessage = false
          } else if (imageData.images === null) {
            this.isLoading = false
            this.showLoadingMessage = false
          }
        });
      } catch (e) {
        this.isLoading = false
        // console.log("Error:", e)
      }
    },
    onReset(event) {
      event.preventDefault()
      this.form.text = ''
      this.form.prompt = ''
    },
    stopListening() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }
  },
  async mounted() {
    // this.form = this.getGenerateData;
    if (this.featuredIntegration.length > 0) {
      this.selectIntegration(this.featuredIntegration[0]);
    }
    this.getThemes().then((themes) => {
      if (themes.length) {
        this.selectStyle(themes[0]);
        this.styles = themes;
      }
    });
    this.$refs.textInput.focus();
    setTimeout(() => {
      const targetSection = document.getElementById('generate');
      targetSection.scrollIntoView({behavior: 'smooth'});
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    this.stopListening();
    next();
  },
  beforeUnmount() {
    this.stopListening();
  }
}
</script>

<style scoped lang="scss">
.loading-message {
  font-size: 14px;
}

.style-select {
  //max-height: 200px;
  overflow-y: auto;

  .theme-item {
    cursor: pointer;
    overflow: hidden;
    border: 4px solid transparent;
    border-radius: 15px;
  }

  .selected {
    border: 4px solid var(--main-color);
  }
}

//.style-height-2 {
//  max-height: 350px;
//}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

.form-block {
  background: var(--background-color);
  border-radius: 12px;

  .block {
    padding: 24px;
  }

  h4 {
    span {
      color: var(--main-color);
    }
  }

  .label {
    font-size: 14px;
    color: var(--text-color);
  }

  .view-all {
    font-size: 14px;
    color: var(--main-color);
  }

  hr {
    color: #4e6181;
  }
}

.integration-item {
  color: var(--text-color);
  font-size: 12px;
  text-align: center;

  .logo-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 24px;
    margin-bottom: 10px;
    background: var(--panel-background-color);
    border: 2px solid transparent;
    cursor: pointer;

    .i-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      margin: auto;
      font-size: 30px;
      max-width: 60%;
      border-radius: 15px;
    }

    &:hover {
      border: 2px solid var(--main-color);
    }
  }
}

.integration-list-item {
  color: var(--text-color);
  //font-size: 12px;
  cursor: pointer;
  padding: 0;
  border-radius: 24px;

  .logo-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 24px;
    //background: var(--panel-background-color);
    cursor: pointer;

    .i-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      margin: auto;
      font-size: 30px;
      max-width: 60%;
      border-radius: 15px;
    }
  }

  span {
    color: var(--text-color);
    //font-size: 14px;
    font-weight: 600;
  }

  p {
    //font-size: 12px;
    font-weight: 300;
    color: #b3bbc5;
    line-height: normal;
  }

  &:hover {
    background: var(--panel-background-color);
  }
}

.i-selected {
  border: 2px solid var(--main-color) !important;
}

.selected-rev {
  font-size: 30px;
  color: var(--text-color);

  img {
    width: 50px;
    border-radius: 15px;
  }

  .icon {
    padding: 10px;
  }
}

.theme-name {
  text-align: center;
  color: var(--text-color);
  font-size: 14px;
}

.preview-link {
  font-size: 14px;
  color: var(--main-color);
  text-decoration: underline;
}

.center-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input-info {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}

.small {
  font-size: 12px;
}
</style>
