const integrationGroups = [
    {
        title: "QR Code Integrations",
        subtitle: "Explore various data types to store in QR codes",
        type: "tiles",
        integrations: [
            {
                id: 1,
                title: "Text",
                subtitle: "Share plain text",
                icon: "text-paragraph",
                label: "Enter text:",
                placeholder: "e.g. Hello!",
                showPreview: false,
            },
            {
                id: 2,
                title: "Website",
                subtitle: "Link to a website",
                icon: "globe",
                placeholder: "e.g. https://vishalb.dev",
                // useShortLink: true,
            },
            {
                id: 3,
                title: "WiFi",
                subtitle: "Share WiFi details",
                icon: "wifi",
                formType: "wifi",
                clearText: true,
                defaultValues: {
                    networkType: 'WEP',
                    networkName: '',
                    networkPassword: '',
                    hiddenNetwork: 'false',
                },
                formatValue: ({
                   networkType,
                   networkName,
                   networkPassword,
                   hiddenNetwork
                }) => {
                    return `WIFI:T:${networkType};S:${networkName};P:${networkPassword};H:${hiddenNetwork};;`;
                }
            },
            {
                id: 4,
                title: "Email",
                subtitle: "Share your email",
                icon: "mailbox",
                label: "Enter your Email:",
                placeholder: "e.g. contact@vishalb.dev",
                clearText: true,
                showPreview: false,
                formatValue: (value) => {
                    return `mailto:${value.trim()}`;
                }
            },
            {
                id: 5,
                title: "Phone",
                subtitle: "Share your phone number",
                icon: "phone",
                label: "Enter your Phone:",
                placeholder: "e.g. +1234567890",
                clearText: true,
                showPreview: false,
                formatValue: (value) => {
                    return `tel:${value.trim()}`;
                }
            },
            {
                id: 6,
                title: "SMS",
                subtitle: "Send a text message",
                icon: "chat",
                clearText: true,
                showPreview: false,
                formatValue: (value) => {
                    return `smsto:${value.trim()}`;
                }
            },
            // {
            //     id: 7,
            //     title: "Contact",
            //     subtitle: "Share contact details",
            //     icon: "person"
            // },
            // {
            //     id: 8,
            //     title: "Event",
            //     subtitle: "Add an event to the calendar",
            //     icon: "calendar-event"
            // }
        ]
    },
    {
        title: "Connect our social profiles",
        subtitle: "Share your social media profiles",
        integrations: [
            {
                id: 9,
                title: "YouTube",
                subtitle: "Link your YouTube channel",
                logo: "https://logo.clearbit.com/youtube.com",
                label: "Enter your YouTube channel / video link:",
                placeholder: "e.g. https://www.youtube.com/watch?v=Tl4bQBfOtbg",
                clearText: true,
                formatValue: (value) => value.trim()
            },
            {
                id: 10,
                title: "Linktree",
                subtitle: "Link your Linktree",
                logo: "https://logo.clearbit.com/linktr.ee",
                label: "Enter your Linktree username:",
                placeholder: "e.g. mylinktree",
                formatValue: (value) => {
                    if (value.includes("https://linktr.ee")) {
                        return value;
                    }
                    return "https://linktr.ee/" + value.trim();
                }
            },
            {
                id: 11,
                title: "Facebook",
                subtitle: "Link your Facebook profile",
                logo: "https://logo.clearbit.com/facebook.com",
                label: "Enter your Facebook username:",
                placeholder: "e.g. myfacebookprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.facebook.com")) {
                        return value;
                    }
                    return "https://www.facebook.com/" + value.trim();
                }
            },
            {
                id: 12,
                title: "Instagram",
                subtitle: "Link your Instagram account",
                logo: "https://logo.clearbit.com/instagram.com",
                label: "Enter your Instagram username:",
                placeholder: "e.g. myinstagramprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.instagram.com")) {
                        return value;
                    }
                    return "https://www.instagram.com/" + value.trim();
                }
            },
            {
                id: 13,
                title: "Twitter",
                subtitle: "Link your Twitter profile",
                logo: "https://logo.clearbit.com/twitter.com",
                label: "Enter your Twitter username:",
                placeholder: "e.g. mytwitterprofile",
                formatValue: (value) => {
                    if (value.includes("https://twitter.com")) {
                        return value;
                    }
                    return "https://twitter.com/" + value.trim();
                }
            },
            {
                id: 14,
                title: "Snapchat",
                subtitle: "Link your Snapchat account",
                logo: "https://logo.clearbit.com/snapchat.com",
                label: "Enter your Snapchat username:",
                placeholder: "e.g. mysnapchataccount",
                formatValue: (value) => {
                    if (value.includes("https://www.snapchat.com")) {
                        return value;
                    }
                    return "https://www.snapchat.com/add/" + value.trim();
                }
            },
            {
                id: 15,
                title: "TikTok",
                subtitle: "Link your TikTok profile",
                logo: "https://logo.clearbit.com/tiktok.com",
                label: "Enter your TikTok username:",
                placeholder: "e.g. mytiktokprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.tiktok.com")) {
                        return value;
                    }
                    return "https://www.tiktok.com/@" + value.trim();
                }
            },
            {
                id: 16,
                title: "LinkedIn",
                subtitle: "Link your LinkedIn profile",
                logo: "https://i.ibb.co/gtFjHc1/Linked-In-logo-initials-png.webp",
                label: "Enter your LinkedIn username:",
                placeholder: "e.g. mylinkedinprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.linkedin.com")) {
                        return value;
                    }
                    return "https://www.linkedin.com/in/" + value.trim();
                }
            },
            {
                id: 17,
                title: "Pinterest",
                subtitle: "Link your Pinterest account",
                logo: "https://logo.clearbit.com/pinterest.com",
                label: "Enter your Pinterest username:",
                placeholder: "e.g. mypinterestprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.pinterest.com")) {
                        return value;
                    }
                    return "https://www.pinterest.com/" + value.trim();
                }
            },
            {
                id: 18,
                title: "WhatsApp",
                subtitle: "Link your WhatsApp number",
                logo: "https://logo.clearbit.com/whatsapp.com",
                label: "Enter your WhatsApp number:",
                placeholder: "e.g. +1234567890",
                clearText: true,
                formatValue: (value) => {
                    if (value.includes("https://wa.me/")) {
                        return value;
                    }
                    return "https://wa.me/" + value.trim();
                }
            },
            {
                id: 19,
                title: "WeChat",
                subtitle: "Link your WeChat ID",
                logo: "https://logo.clearbit.com/wechat.com",
                label: "Enter your WeChat ID:",
                placeholder: "e.g. mywechatid",
                showPreview: false,
                clearText: true,
                formatValue: (value) => value.trim()
            },
            {
                id: 20,
                title: "Reddit",
                subtitle: "Link your Reddit profile",
                logo: "https://logo.clearbit.com/reddit.com",
                label: "Enter your Reddit username:",
                placeholder: "e.g. myredditprofile",
                formatValue: (value) => {
                    if (value.includes("https://www.reddit.com")) {
                        return value;
                    }
                    return "https://www.reddit.com/user/" + value.trim();
                }
            },
            {
                id: 21,
                title: "Discord",
                subtitle: "Link your Discord server",
                logo: "https://logo.clearbit.com/discord.com",
                label: "Enter your Discord username:",
                placeholder: "e.g. mydiscordserver",
                formatValue: (value) => {
                    if (value.includes("https://discord.gg")) {
                        return value;
                    }
                    return "https://discord.gg/" + value.trim();
                }
            },
            {
                id: 22,
                title: "Telegram",
                subtitle: "Link your Telegram account",
                logo: "https://logo.clearbit.com/telegram.org",
                label: "Enter your Telegram username:",
                placeholder: "e.g. mytelegramprofile",
                formatValue: (value) => {
                    if (value.includes("https://t.me")) {
                        return value;
                    }
                    return "https://t.me/" + value.trim();
                }
            }
        ]
    },
    {
        title: "Collect payments",
        subtitle: "Connect to accept payments through QR code",
        integrations: [
            {
                id: 23,
                title: "PayPal",
                subtitle: "Request money with PayPal",
                logo: "https://logo.clearbit.com/paypalbenefits.com",
                label: "Enter your PayPal username:",
                placeholder: "e.g. john",
                formatValue: (value) => {
                    if (value.includes("https://www.paypal.me/")) {
                        return value;
                    }
                    return "https://www.paypal.me/" + value.trim();
                }
            },
            {
                id: 24,
                title: "Square",
                subtitle: "Accept payments with Square",
                logo: "https://logo.clearbit.com/squareup.com",
                label: "Enter your Square checkout links:",
                placeholder: "e.g. https://squareup.com/pay-invoice/ABCD1234",
            },
            {
                id: 25,
                title: "Stripe",
                subtitle: "Collect payments with Stripe",
                logo: "https://logo.clearbit.com/stripe.com",
                label: "Enter your Stripe payment links:",
                placeholder: "e.g. https://buy.stripe.com/ABCD1234",
            },
            {
                id: 26,
                title: "Venmo",
                subtitle: "Receive payments with Venmo",
                logo: "https://logo.clearbit.com/venmo.com",
                label: "Enter your Venmo username:",
                placeholder: "e.g. john",
                formatValue: (value) => {
                    if (value.includes("https://venmo.com/")) {
                        return value;
                    }
                    return "https://venmo.com/" + value.trim();
                }
            },
            {
                id: 27,
                title: "UPI",
                subtitle: "Accept payments with UPI",
                logo: "https://i.ibb.co/HxDS2YQ/bhim-logo-2.png",
                label: "Enter your VPA:",
                placeholder: "e.g. example@upi",
                showPreview: false,
                formatValue: (value) => {
                    return "upi://pay?pa=" + value.trim();
                }
            },
        ]
    }
];

const featuredIntegration = [
    integrationGroups[0].integrations[1],
    // integrationGroups[0].integrations[0],
    integrationGroups[1].integrations[4],
    integrationGroups[1].integrations[7],
    integrationGroups[1].integrations[3],
    integrationGroups[1].integrations[1],
    integrationGroups[0].integrations[2],
];

export default {
    featuredIntegration,
    integrationGroups
}
